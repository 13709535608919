import "./App.css";

//import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/nav/nav";
import Test from "./pages/test/Test";
import { UserContextProvider } from "./context/user-context";
import { ContextService } from "./context/context-service";
import Footer from "./components/footer/footer";

import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Services } from "./pages/services";
import Test2 from "./pages/test2/Test2";
import Post1 from "./posts/Post1";
import Post2 from "./posts/Post2";
import Test3 from "./pages/test3/Test3";
import CareerCoaching from "./pages/career-coaching";
import ComingSoon from "./pages/coming-soon";
import ILab from "./pages/ilab";
import CookieConsent from "./components/consent/cookie-consent";
import Jobs from "./pages/jobs";
import JobApply from "./pages/jobs/job-apply";
import TrainingEnrollment from "./pages/bsg-academy/training-enrollment";
import React, { useEffect, useState } from "react";
import SignIn from "./my/sign-in";
import Training from "./pages/training";
import SignOut from "./my/sign-out";
import Contact from "./pages/contact";
import CaseStudy from "./pages/use-study";
import Events from "./pages/events";

import awsconfig from "./api/aws-amplify";
import Amplify from "aws-amplify";
import SignUp from "./my/sign-up";
import ChangePassword from "./my/change-password";
import MyHome from "./my/my-home";
import ForgotPassword from "./my/forgot-password";
import MyPayment from "./my/my-payment";
import MyDocument from "./my/my-document";
import MyCandidates from "./my/my-candidates";
import Candidate from "./my/my-candidate";
import MyProfile from "./my/my-profile";
import MySchedule from "./my/my-schedule";
import MyReferral from "./my/my-referral";
import Promo from "./my/promo";
import LinkedInSignIn from "./user/sign-in";
import Page from "./my/page";
import BSGSSOSignIn from "./my/bsg-sso";
import MyCommunity from "./my/my-community";
import MyTest from "./my/my-test";
import Neo4j from "./pages/test/neo4j";
import Onboarding from "./pages/onboarding"; 

Amplify.configure(awsconfig);

function App() {
  //const context = useContext(userContext);

  let [context, setContext] = useState(new ContextService());

  let [contextUpdate, setContextUpdate] = useState(false);

  const forceUpdate = () => {
    setContextUpdate(!contextUpdate);
  };

  useEffect(() => {
    //document.title = "BSG:Welcome";
    context.init().then(() => {
      //context.setUser(data.user as DUser);
      //context.setBusiness(data.business as DBusiness);
      //console.log("Init: " + JSON.stringify(context.user));
      //forceUpdate();
    });
  }, []);

  return (
    <UserContextProvider value={{ context, contextUpdate, forceUpdate }}>
      <ThemeProvider theme={theme}>

      <BrowserRouter>
          <div className="App">
            <Nav></Nav>
            <div className="App-main">
        <Routes>
              <Route />
              <Route path="/" element={ <MyHome />} />
              <Route path="/home" element={ <MyHome />} />
              <Route path="/intro" element={ <Promo/>} />
              <Route path="/page/:id" element={<Page/>} />
              <Route path="/page/:id/:lang" element={<Page/>} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/jobs/:id" element={<Jobs/> } />
              <Route path="/jobs/:id/:action" element={<Jobs/> } />
              <Route path="/job-apply" element={<JobApply/>} />
              <Route path="/job-apply/:id" element={<JobApply/>} />

              <Route path="/training"  element={<Training/>} />
              <Route path="/training-enrollment" element={<TrainingEnrollment/>} />
              <Route path="/training-enrollment/:id" element={<TrainingEnrollment/>} />
              <Route path="/ilab" element={<ILab/>} />
              <Route path="/case-study" element={<CaseStudy/>} />
              <Route path="/case-study/:category" element={<CaseStudy/>} />
              <Route path="/contact-us" element={<Contact/>} />
              <Route path="/career-coaching" element={<CareerCoaching/>} />
              <Route path="/profile" element={<MyProfile/>} />
              <Route path="/pay" element={<MyPayment/>} />
              <Route path="/referral" element={<MyReferral/>} />
              <Route path="/candidates" element={<MyCandidates/>} />
              <Route path="/community" element={<MyCommunity/>} />
              <Route path="/my-test" element={<MyTest/>} />
              <Route path="/candidates/:company" element={<MyCandidates />}/>
              <Route path="/candidate/:company/:id" element={<Candidate />} />
              <Route path="/documents" element={<MyDocument/>} />
              <Route path="/schedule" element={<MySchedule/>} />
              <Route path="/linkedin-sign-in" element={<LinkedInSignIn />} />
              <Route path="/sso-sign-in" element={<BSGSSOSignIn/>} />
              <Route path="/sign-in" element={<SignIn/>} />
              <Route path="/sign-up" element={<SignUp/>} />
              <Route path="/sign-up/:step" element={<SignUp/>} />
              <Route path="/sign-out" element={<SignOut/>} />
              <Route path="/change-password" element={<ChangePassword/>} />
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="/forgot-password/:step" element={<ForgotPassword />} />
              <Route path="/events" element={<Events/>} />
              <Route path="/events/:id" element={<Events/>} />
              <Route path="/events/:id/:action" element={<Events/>} />
              <Route path="/test" element={<Test/>} />
              <Route path="/test2" element={<Test2/>} />
              <Route path="/test3" element={<Test3/>} />
              <Route path="/services" element={<Services/>} />
              <Route path="/post1" element={<Post1/>} />
              <Route path="/post2" element={<Post2/>} />
              <Route path="/coming-soon" element={<ComingSoon/>} />

              <Route path="/test/neo4j" element={ <Neo4j />} />
              <Route path="/onboarding" element={ <Onboarding />} />
              
              </Routes>

            </div>
            <Footer></Footer>
            <CookieConsent></CookieConsent>
          </div>

          </BrowserRouter>
      </ThemeProvider>
    </UserContextProvider>
  );
}

export default App;
