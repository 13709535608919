import { ContextService } from "../context/context-service";
import {
  DCognitoCredentials,
  DCognitoUser,
  DLog,
  DLogItem,
  DNameValue,
  DOpportunity,
} from "../model";


export class BSGAPI2 {
 
  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }

  // get my ui
  static getUI(credentials: DCognitoCredentials) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({}),
      };
      fetch("/api2/my-ui", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static myTest(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/my-test", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getMyProfile(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/get-my-profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }  
}
