import { useEffect, useRef, useState } from "react";

import useScrollTrigger from "../../hooks/scroll-trigger";
import { Container, Fade, Grid } from "@mui/material/";
import "../../custom.d.ts";
import "./style.scss";

import Banner from "../../components/banner/banner";
import VideoThemeCard from "../../components/card/video-theme-card";

import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SpeedIcon from "@mui/icons-material/Speed";

import ContactForm from "../../components/form/contact-form";

const Onboarding = (props: any) => {

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  useEffect(() => {
    document.title = "BSG:iLab";

    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    window.scrollTo(0, 0);

    //myApp.user?.setLinkedInFirstName(myApp.user, "Julian");
  });

  return (
    <div>

      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>Onboarding</h1>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>Welcome -- please take a few minutes to complete onboarding process. </h2>
              </Grid>
            </Grid>
          </Banner>
        </div>
      </Fade>

    </div>
  );
};

export default Onboarding;
