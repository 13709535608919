import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Container, Fade, Grid } from "@mui/material";
import { API } from "aws-amplify";
import { useNavigate , useParams} from "react-router";
import { DPerson, DUser } from "../model";
import { ContextService } from "../context/context-service";
import { DPayment } from "../model/my-bsg";
import PaymentCard from "../components/card/payment-card";
import CandidateCard from "../components/card/candidate-card";
import { BSGAPI } from "../auth/bsg-api";
import Loading from "../components/loading/loading";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface Person {
  firstname: string;
  lastname: string;
}

export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

const MyCandidates = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const [candidates, setCandidates] = useState([] as DPerson[]);

  let [key, setKey] = useState(new Date().toString());

  let [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  //const companyId = props.match.params.company;

  const { companyId } = useParams(); 

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        forceUpdate();
        //getMyCandidates();
        BSGAPI.getCandidates(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          companyId
        ).then((response: any) => {
          if (response.status == "ok") {
            setCandidates(response.data as DPerson[]);
            setKey(new Date().toString());

            setLoading(false);
          } else if (response.status == "token-expired") {
            setError("Your session has expired. Please login again.");
            setShowError(true);
            setTimeout(() => {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            }, 3000);
          } else {
            // TODO: exception handling ..

            setError("Some error occurred. Please try again later.");
            setShowError(true);
          }
        });
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/pay`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    document.title = `${ContextService.SITE_NAME}:My Candidates`;
    window.scrollTo(0, 0);
  }, [companyId]);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>My Candidates</h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>We are glad you are part of the team. </h2>
          </Grid>
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          {loading ? (
            <div>
              <Loading show={loading} />
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
              >
                {showError && (
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                      setShowError(false);
                    }}
                    severity="error"
                  >
                    {error}
                  </MuiAlert>
                )}
              </Grid>
            </div>
          ) : (
            <Grid
              container
              spacing={8}
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              alignContent="center"
            >
              {candidates?.map((candidate, index) => {
                return (
                  <Grid
                    key={`grid-${key}-${candidate.id}`}
                    container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    justifyContent="center"
                  >
                    {candidate && (
                      <CandidateCard
                        key={`candidate-${key}-${candidate.id}`}
                        candidate={candidate}
                        onClick={() => {
                          history(
                            `/candidate/${candidate.companyId}/${candidate.id}`
                          );
                        }}
                        onClickText="View"
                      ></CandidateCard>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </div>
    </div>
  );
};

export default MyCandidates;
